import { get, isEmpty } from 'lodash';
import { useContentInfo, useFetchContent } from 'app/content/hooks';
import { useMemo } from 'react';

function useSearchSortPreferences() {
  const { sortPrefsModelName } = useContentInfo();
  const sortPrefsContent = useFetchContent(sortPrefsModelName);

  return useMemo(() => {
    if (!isEmpty(sortPrefsContent)) {
      return get(sortPrefsContent, 'fields.preferredProductSort.value.id');
    } else {
      return {};
    }
  }, [sortPrefsContent]);
}

export default useSearchSortPreferences;
