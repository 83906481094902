import React from 'react';
import {
  Map,
  Marker,
  MapControl,
  ControlPosition
} from '@vis.gl/react-google-maps';

import { GetDirectionsButton } from 'app/about/components/About/components';

const DealerMaps = ({
  application,
  longitude = 0,
  latitude = 0,
  zoom = 11,
  restrictSize = 1
}) => {
  const latLngBounds = {
    north: latitude + restrictSize,
    south: latitude - restrictSize,
    west: longitude - restrictSize,
    east: longitude + restrictSize
  };

  return (
    <ErrorBoundary>
      <Map
        id={'aboutMap'}
        zoom={zoom}
        center={{ lat: latitude, lng: longitude }}
        disableDefaultUI={true}
        clickableIcons={false}
        gestureHandling={'cooperative'}
        restriction={{
          latLngBounds: latLngBounds
        }}
      >
        <Marker position={{ lat: latitude, lng: longitude }} />
        <MapControl position={ControlPosition.BLOCK_START_INLINE_CENTER}>
          <GetDirectionsButton className="p-3 mt-3" application={application} />
        </MapControl>
      </Map>
    </ErrorBoundary>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
  }
}

export default DealerMaps;
export { DealerMaps };
