import { useFormatMessage } from 'app/common/hooks';
import { Currency, Icon, RadioField } from 'app/common/components';
import React, { useContext } from 'react';
import { CheckoutContext } from 'app/checkout/contexts';
import { setSelectedFulfillmentOption } from 'app/checkout/contexts/reducers/checkoutReducer';
import { get, noop } from 'lodash';
import messages from '../FulfillmentInfoWithOptions.messages';
import FulfillmentFees from './FulfillmentFees';
import CheckoutModal from 'app/checkout/components/CheckoutLayout/components/CheckoutModal/CheckoutModal.js';
import DeliveryOptionRequirements from './DeliveryOptionRequirements';

const DeliveryOptions = ({ disabled, isSubmitting, required = true }) => {
  const formatMessage = useFormatMessage();
  const { checkoutDispatch, fulfillmentOptions, failedOptions } =
    useContext(CheckoutContext);
  let index = 0;
  const failedFulfillmentOptions = failedOptions || [];
  return (
    <fieldset
      className={'flex flex-col items-start justify-start mb-2'}
      disabled={disabled}
    >
      <legend className={'mb-4 text-gray-900 font-bold lg:text-lg'}>
        <span>{formatMessage(messages.deliveryMethod)}</span>
        {required && <span className="ml-1 text-red-500">*</span>}
      </legend>

      {fulfillmentOptions.map((option, idx) => {
        index = idx;
        return (
          <FulfillmentOption
            key={index}
            option={option}
            index={index}
            required={required}
            disabled={disabled || isSubmitting}
            onSelect={() => {
              setSelectedFulfillmentOption(checkoutDispatch, option);
            }}
          />
        );
      })}
      {failedFulfillmentOptions.map((option, idx) => {
        return (
          <FailedFulfillmentOption
            key={index + idx + 1}
            option={option}
            index={index + idx + 1}
            required={required}
            disabled={true}
            onSelect={() => {
              noop();
            }}
          />
        );
      })}
    </fieldset>
  );
};

const FailedFulfillmentOption = ({ option, index, required }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const modalRef = React.useRef(undefined);
  const formatMessage = useFormatMessage();
  return (
    // eslint-disable-next-line
    <a
      href={'#'}
      className={'text-base text-red-800 hover:cursor-pointer'}
      onClick={e => {
        e.preventDefault();
        setModalOpen(true);
      }}
      aria-label={formatMessage(messages.unavailableAriaLabel)}
    >
      <FulfillmentOption
        option={option}
        index={index}
        required={required}
        disabled={true}
        noPrice={true}
      >
        <span className={'text-red-800 box-border'}>
          <Icon name={'exclamation-circle'} className={'pl-1'} />
        </span>
        <CheckoutModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalRef={modalRef}
          {...DeliveryOptionRequirements({ deliveryValidation: option })}
        />
      </FulfillmentOption>
    </a>
  );
};

const FulfillmentOption = ({
  option,
  index,
  required,
  disabled,
  onSelect,
  noPrice = false,
  children = null
}) => {
  const { selectedFulfillmentOption } = useContext(CheckoutContext);
  const selectedLevel = getOptionName(selectedFulfillmentOption);
  const optionValue = getOptionName(option);
  return (
    <>
      <RadioField
        name={'fulfillmentDeliveryOption'}
        value={optionValue}
        key={index}
        required={required}
        disabled={disabled}
        onSelect={onSelect}
        skipHoverNotAllowed={true}
        checked={selectedLevel === optionValue}
      >
        <DeliveryOption option={option} noPrice={noPrice} />
        {children}
      </RadioField>
    </>
  );
};

const DeliveryOption = ({ option, noPrice }) => {
  const formatMessage = useFormatMessage();
  const serviceLevel = get(option, 'serviceLevel', null);
  if (!serviceLevel) {
    return null;
  }
  const label = formatMessage(messages[option.serviceLevel]);
  if (noPrice) {
    return (
      <>
        <span>{label}</span>
        <span className={'pl-1 text-sm'}>
          <EstimatedDays {...getEstimatedDays(option)} noPrice={noPrice} />
        </span>
      </>
    );
  }

  return (
    <>
      <span>{label}</span>
      <span className={'pl-1 text-sm'}>
        (
        <EstimatedDays {...getEstimatedDays(option)} noPrice={noPrice} />
        <DeliveryPrice price={option.price} />)
        <FulfillmentFees option={option} />
      </span>
    </>
  );
};

const DeliveryPrice = ({ price }) => {
  const formatMessage = useFormatMessage();
  let message;
  if (!price || price.amount === 0) {
    message = formatMessage(messages.freeDelivery);
  } else {
    message = <Currency amount={price.amount} currency={price.currency} />;
  }
  return <span className={''}>{message}</span>;
};

const EstimatedDays = ({ minDays, maxDays, noPrice }) => {
  const formatMessage = useFormatMessage();
  let message = null;
  let singleDay = null;

  const hasDays = minDays || maxDays;
  if (!hasDays) {
    return null;
  }
  if (minDays && maxDays) {
    if (minDays === maxDays) {
      message = formatMessage(messages.estimatedDaysSingle, {
        days: minDays
      });
    } else {
      message = formatMessage(messages.estimatedDaysRange, {
        minDays,
        maxDays
      });
    }
  } else {
    singleDay = minDays || maxDays;
    message = formatMessage(messages.estimatedDaysSingle, {
      days: singleDay
    });
  }

  if (noPrice) {
    return <span>{message}</span>;
  }
  return <span>{message} - </span>;
};

function getOptionName(fulfillmentOption) {
  const serviceLevel = get(fulfillmentOption, 'serviceLevel');
  // combine into "-" separated string
  const calculatorIds = get(fulfillmentOption, 'calculatorIds', [])
    .sort()
    .map(calculatorId => calculatorId)
    .join('-');
  return serviceLevel + calculatorIds;
}

function getEstimatedDays(fulfillmentOption) {
  const minDays = get(fulfillmentOption, 'estimatedMinDaysToFulfill');
  const maxDays = get(fulfillmentOption, 'estimatedMaxDaysToFulfill');
  return {
    minDays,
    maxDays
  };
}

export default DeliveryOptions;
export { DeliveryOption };
