/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, isString, join, map, size } from 'lodash';
import PropTypes from 'prop-types';
import TagManager from 'app/common/tagmanager';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import { CheckoutContext } from 'app/checkout/contexts';
import {
  Icon,
  Link,
  LoadingIcon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import { CartContext, TenantContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import { useCheckoutApi } from './hooks';
import messages from './ReviewInfoReadOnly.messages';
import { goToCheckoutStep } from 'app/checkout/components/CheckoutLayout/components/hooks';
import { PAYMENT_INFO_PATH } from 'app/checkout/components/CheckoutLayout/CheckoutPaths';

/**
 * Render component for the read-only review of the cart email address during
 * checkout.
 */
const ReviewInfoReadOnly = ({ disabled = true, handleEdit, history }) => {
  const formatMessage = useFormatMessage();
  const {
    cart,
    setCart,
    setGuestToken,
    hasItemConfigErrors,
    hasItemAttributeErrors,
    guestToken
  } = React.useContext(CartContext);
  const emailAddress = get(cart, 'emailAddress');
  const {
    error: checkoutError,
    exception: checkoutException,
    sendCallback
  } = useCheckoutApi(cart, guestToken);
  const hasItemErrors = hasItemAttributeErrors || hasItemConfigErrors;
  const hasErrors =
    !isEmpty(get(cart, 'attributes.errors')) || hasItemErrors || checkoutError;
  const { isSubmittingOrder, setIsSubmittingOrder } =
    React.useContext(CheckoutContext);
  const [failureType, failureMessage] = useFailureMessage(checkoutException);
  const items = get(cart, 'cartItems', []);
  const gtmPushed = React.useRef(false);
  const { isAuthenticated } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (gtmPushed.current || disabled) {
      return;
    }

    TagManager.dataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 2
          },
          products: items.map((item, i) => {
            const {
              quantity,
              sku,
              unitPrice,
              attributes: { categoryNames, description }
            } = item;
            return {
              category: join(categoryNames, ', '),
              id: sku,
              name: description,
              position: i,
              price: get(unitPrice, 'amount', 0.0),
              quantity
            };
          })
        }
      }
    });
    gtmPushed.current = true;
  }, [disabled, items]);

  const isInventoryUnavailable = failureType === 'FAILED_INVENTORY_CHECK';
  const isNonStocking = failureType === 'NON_STOCKING';
  const isPaymentError = failureType === 'FAILED_PAYMENT_TRANSACTION';

  console.log('failureType:', failureType);
  if (!disabled && isPaymentError) {
    goToCheckoutStep(
      history,
      PAYMENT_INFO_PATH,
      undefined,
      0,
      'payment-failure'
    )();
    return null;
  } else {
    return (
      <>
        {hasErrors &&
          (isInventoryUnavailable ? (
            <WarningMessages failureMessage={failureMessage} />
          ) : (
            <ErrorMessages
              failureMessage={failureMessage}
              hasItemErrors={hasItemErrors}
            />
          ))}
        <section className="w-full mb-2">
          <strong className="flex flex-col">
            <span className="mb-3">{formatMessage(messages.receiptLabel)}</span>
            <span className="font-normal">{emailAddress}</span>
          </strong>
          <TertiaryButton
            onClick={handleEdit}
            disabled={disabled}
            size={TertiaryButton.Size.SMALL}
          >
            {formatMessage(messages.editLabel)}
          </TertiaryButton>
        </section>
        <footer className="flex justify-end w-full">
          {isInventoryUnavailable || isNonStocking ? (
            <>
              <SecondaryButton
                className="mr-2"
                disabled={disabled}
                onClick={() => {
                  history.push({ pathname: '/cart' });
                }}
              >
                {formatMessage(messages.modifyCart)}
              </SecondaryButton>
            </>
          ) : (
            !isAuthenticated && (
              <SecondaryButton
                className="mr-2"
                disabled={disabled}
                onClick={() => AuthService.loginWithRedirect()}
              >
                {formatMessage(messages.signInLabel)}
              </SecondaryButton>
            )
          )}
          <PrimaryButton
            className="relative"
            disabled={
              disabled || hasItemErrors || isSubmittingOrder || isEmpty(items)
            }
            onClick={() => {
              if (!disabled && !hasItemErrors) {
                setIsSubmittingOrder(true);
                ReviewInfoReadOnly.handleSubmit({
                  acceptAvailability:
                    // don't overwrite if already accepted
                    cart.attributes.ACCEPT_ITEM_AVAILABILITY ||
                    isInventoryUnavailable,
                  history,
                  sendCallback,
                  setCart,
                  setGuestToken
                }).finally(() => {
                  setIsSubmittingOrder(false);
                });
              }
            }}
          >
            <div
              className={classNames({ 'text-transparent': isSubmittingOrder })}
            >
              {isInventoryUnavailable
                ? formatMessage(messages.checkoutAnyway)
                : formatMessage(messages.submitLabel)}
            </div>
            {isSubmittingOrder && (
              <div className="absolute inset-0 flex justify-center items-center">
                <LoadingIcon className="text-blue-500" />
              </div>
            )}
          </PrimaryButton>
        </footer>
      </>
    );
  }
};

const WarningMessages = ({ failureMessage }) => {
  const formatMessage = useFormatMessage();
  return (
    <section className="mb-4 px-2 py-1 text-sm text-yellow-700 border border-solid border-yellow-400 bg-yellow-100 rounded md:px-4 md:py-2 lg:text-base ">
      <header className="flex items-center pb-1 mb-1 border-b-2 border-solid border-yellow-400 font-medium text-base leading-tight md:pb-2 md:mb-2 lg:text-lg">
        <Icon className="ml-2 mr-3 md:mr-4" name="exclamation-triangle" />
        <span>{formatMessage(messages.inventoryUnavailableTitle)}</span>
      </header>
      <section
        id="general-order-error"
        className="leading-snug lg:leading-normal"
      >
        {failureMessage}
      </section>
    </section>
  );
};

const ErrorMessages = ({ hasItemErrors, failureMessage }) => {
  const formatMessage = useFormatMessage();
  return (
    <section className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-300 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
      <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
      {hasItemErrors ? (
        <div id="item-config-error">
          <span>{formatMessage(messages.itemConfigErrors)}</span>{' '}
          <Link
            className="text-sm text-red-600 font-bold hover:text-red-700 focus:text-red-700 focus:outline-none active:text-red-500"
            to="/cart"
          >
            {formatMessage(messages.itemConfigErrorsAction)}
          </Link>
        </div>
      ) : (
        <div id="general-order-error">{failureMessage}</div>
      )}
    </section>
  );
};

ReviewInfoReadOnly.handleSubmit = ({
  acceptAvailability,
  history,
  sendCallback,
  setCart,
  setGuestToken,
  requestId,
  securityCodes = {}
}) =>
  sendCallback({
    method: 'post',
    data: { requestId, securityCodes },
    params: { acceptAvailability }
  })
    .then(data => {
      if (!isEmpty(data)) {
        const submittedCart = data.cart;
        const address = get(submittedCart, 'fulfillmentGroups[0].address');

        TagManager.dataLayer({
          event: 'purchase',
          ecommerce: {
            transaction_id: get(submittedCart, 'orderNumber'),
            value: get(submittedCart, 'cartPricing.total.amount', 0.0),
            tax: get(submittedCart, 'cartPricing.totalTax.amount', 0.0),
            shipping: get(
              submittedCart,
              'cartPricing.fulfillmentTotal.amount',
              0.0
            ),
            currency: submittedCart.currency,
            // coupon: ,
            items: get(submittedCart, 'cartItems', []).map((item, i) => {
              const {
                quantity,
                sku,
                unitPrice,
                attributes: { categoryNames, productDescription }
              } = item;

              let categories = {};
              for (let i = 0; i < categoryNames.length; i++) {
                const catName = categoryNames[i];
                if (i === 0) {
                  categories = { item_category: catName };
                } else {
                  const key = 'item_category' + (i + 1);
                  categories[key] = catName;
                }
              }

              return {
                index: i,
                item_id: sku,
                item_name: productDescription,
                price: get(unitPrice, 'amount', 0.0),
                quantity: quantity,
                affiliation: 'Aurora Parts to Go',
                ...categories
                // coupon: ,
                // discount: ,
                // item_brand: ,
                // item_list_id: ,
                // item_list_name: ,
                // item_variant: ,
                // location_id: ,
              };
            })
          },
          leadsUserData: {
            email: get(submittedCart, 'emailAddress'),
            phone_number: get(address, 'phonePrimary.phoneNumber'),
            address: {
              first_name: get(address, 'firstName'),
              last_name: get(address, 'lastName'),
              street: get(address, 'addressLine1'),
              city: get(address, 'city'),
              region: get(address, 'stateProvinceRegion'),
              country: get(address, 'country'),
              postal_code: get(address, 'postalCode')
            }
          }
        });
        // there is no in-process cart anymore
        setCart({});
        setGuestToken(null);

        const state = get(history, 'location.state', {});

        history.push(`/checkout/confirmation/${submittedCart.orderNumber}`, {
          ...state,
          reviewComplete: true,
          editing: false,
          submittedCart: submittedCart
        });
      }
    })
    .catch(err => {
      if (!isEmpty(err.cart)) {
        setCart({
          ...err.cart,
          checkoutError: {
            failureMessage: err.failureMessage,
            failureType: err.failureType,
            itemFailureMessages: err.itemFailureMessages
          }
        });
      }
    });

function useFailureMessage(checkoutException) {
  const { cart } = React.useContext(CartContext);
  const failureType = get(checkoutException, 'failureType');
  const { application } = React.useContext(TenantContext);
  const formatMessage = useFormatMessage();
  return React.useMemo(() => {
    if (!isEmpty(failureType)) {
      return [
        failureType,
        getCheckoutFailureMessage({
          application,
          cart,
          checkoutException,
          formatMessage
        })
      ];
    }

    const partNumbers = get(cart, 'attributes.errors.NON_STOCKING.partNumbers');
    const requiresRemoval = get(
      cart,
      'attributes.errors.NON_STOCKING.requiresRemoval',
      false
    );

    if (isEmpty(partNumbers)) {
      return [null, null];
    }

    return [
      'NON_STOCKING',
      formatMessage(messages.nonStockingError, {
        parts: join(partNumbers, "', '"),
        quantity: size(partNumbers),
        isCart: true,
        requiresRemoval
      })
    ];
  }, [application, cart, checkoutException, failureType, formatMessage]);
}

function getCheckoutFailureMessage({
  application,
  cart,
  checkoutException,
  formatMessage
}) {
  const phoneNumber = get(application, 'phoneNumber');
  const failureType = get(checkoutException, 'failureType');

  switch (failureType) {
    case 'INVALID_PAYMENT_CONFIG':
    case 'FAILED_PAYMENT_CONFIRMATION':
      return formatMessage(messages.paymentError);
    case 'INVALID_OFFER_OR_OFFER_CODE_USAGE':
      return formatMessage(messages.invalidOffer);
    case 'INVALID_DEALER_LOCATION':
      return formatMessage(messages.invalidDealer);
    case 'NON_STOCKING': {
      const parts = get(cart, 'attributes.errors.NON_STOCKING.partNumbers', []);
      return formatMessage(messages.nonStockingError, {
        parts: join(parts, "', '"),
        quantity: size(parts),
        isCart: true,
        requiresRemoval: false
      });
    }
    case 'FAILED_INVENTORY_CHECK': {
      const itemFailureMessages = get(
        checkoutException,
        'itemFailureMessages',
        []
      );
      return (
        <>
          <FormattedMessage
            {...messages.inventoryUnavailable}
            values={{
              phoneNumber:
                phoneNumber === undefined ? (
                  'NA'
                ) : (
                  <a
                    className="font-bold hover:text-yellow-800 focus:text-yellow-800 active:text-yellow-600"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </a>
                ),
              breakLine: <br />,
              breakLineWide: <div />
            }}
          >
            {(...args) => (
              <div>
                {map(args, (arg, i) => {
                  if (isString(arg)) {
                    return <span key={i}>{arg}</span>;
                  }

                  if (arg.type === 'br') {
                    return <br key={i} />;
                  }

                  if (arg.type === 'div') {
                    return <div key={i} className="mb-2 lg:mb-3" />;
                  }

                  return <React.Fragment key={i}>{arg}</React.Fragment>;
                })}
              </div>
            )}
          </FormattedMessage>
          <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
            {map(itemFailureMessages, partNumber => {
              return (
                <li key={partNumber} className="mt-1">
                  {formatMessage(messages.itemUnavailable, { partNumber })}
                </li>
              );
            })}
          </ul>
        </>
      );
    }
    default:
      return formatMessage(messages.generalError);
  }
}

ReviewInfoReadOnly.propTypes = {
  /** Whether to disable all the actions */
  disabled: PropTypes.bool,
  /** Handler for when the edit button is activated */
  handleEdit: PropTypes.func.isRequired
};

export default withRouter(ReviewInfoReadOnly);
export { ReviewInfoReadOnly };
