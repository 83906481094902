import React from 'react';

import { Environment } from 'app/common/services';

function useContentInfo() {
  return React.useMemo(
    () => ({
      browseContentItemEndpoint: Environment.get(
        'BROWSE_CONTENT_ITEM_ENDPOINT',
        'catalog-browse/browse/content-items'
      ),
      aboutUsModelName: Environment.get('MODEL_ABOUT_US', 'About Us'),
      sortPrefsModelName: Environment.get(
        'MODEL_SORT_PREFS',
        'Preferred Product Sort'
      )
    }),
    []
  );
}

export default useContentInfo;
