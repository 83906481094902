import { useContext, useEffect, useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import { useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import useContentInfo from '../useContentInfo';
import { TenantContext } from 'app/common/contexts';

function useFetchContent(modelName) {
  const { browseContentItemEndpoint } = useContentInfo();
  const [content, setContent] = useState({});

  const {
    application,
    resolving: resolvingTenant,
    tenant
  } = useContext(TenantContext);

  const applicationReady = useMemo(() => {
    if (resolvingTenant) {
      setContent({});
    }
    return !isEmpty(tenant) && !isEmpty(application) && !resolvingTenant;
  }, [tenant, application, resolvingTenant]);

  const {
    error,
    exception,
    response,
    sendCallback: fetchContent,
    requestStatus
  } = useRestApi(
    browseContentItemEndpoint,
    { params: { modelName: modelName } },
    false
  );

  useEffect(() => {
    if (requestStatus === IN_PROGRESS || !isEmpty(content)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Content' });
      return;
    }

    if (requestStatus === COMPLETE && isEmpty(content)) {
      const results = response.results;
      if (isEmpty(results)) {
        return;
      }

      setContent(results[0]);
      return;
    }

    if (applicationReady && requestStatus === PENDING) {
      fetchContent();
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    content,
    fetchContent,
    applicationReady
  ]);

  return content;
}

export default useFetchContent;
